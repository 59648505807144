import React, {useRef, useEffect} from 'react'

const PayslipViewer = ({content}) => {
  const iframeRef = useRef(null)

  const adjustIframeHeight = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current
      try {
        // Adjust the height based on the iframe's content
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px'
      } catch (error) {
        console.error('Error accessing iframe content:', error)
      }
    }
  }

  useEffect(() => {
    const iframe = iframeRef.current

    if (iframe) {
      iframe.addEventListener('load', adjustIframeHeight)

      // Dynamically update the iframe content
      const doc = iframe.contentWindow.document
      doc.open()
      doc.write(content)
      doc.close()

      adjustIframeHeight()

      return () => {
        iframe.removeEventListener('load', adjustIframeHeight)
      }
    }
  }, [content]) // Recalculate height whenever content changes

  return <iframe ref={iframeRef} style={{width: '100%', border: 'none'}} />
}

export default PayslipViewer
